// ======================================================================
// 04-elements - Body
// ======================================================================

body {
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

.no-scroll {
  overflow-y: hidden;
}