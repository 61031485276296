// ======================================================================
// 05-objects - Grayscale
// ======================================================================

.grayscale {
  transition: filter 0.4s ease-in-out;
  filter: grayscale(100%);
}

.color {
  &:hover {
    .grayscale {
      filter: none;
    }
  }
}
