// ======================================================================
// 04-elements - Buttons
// ======================================================================

.menu-butt {
  @include button($inverted-button);
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;
  @include media("screen", ">=tablet") {
    display: none;
  }
}
