// ======================================================================
// 06-components - Player
// ======================================================================

.video-container {
  position: relative;
}

.video-controls {
  display: none;



  &:hover {
    transition: all 0.5s ease;
    background-color: rgba($jet, 0.7);
  }

  .videocontrols & {
    display: flex;
  }
  position: absolute;
  bottom: rem(24px);
  right: rem(8px);
  height: rem(40px);
  border-radius: 20px;
  padding: rem(4px);
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    position: relative;
    font-size: rem(12px);
    border: none;
    outline: none;
    background-color: transparent;
    width: rem(32px);
    height: rem(32px);
    margin: 0 4px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button * {
    pointer-events: none;
  }

  svg {
    width: rem(22px);
    height: rem(22px);
    fill: #fff;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .volume {
    display: none;
  }
}

.video-controls.hide {
  transition: all 0.5s ease;
  opacity: 0;
  pointer-events: none;
}
