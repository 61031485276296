// ======================================================================
// 04-elements - Media
// ======================================================================

figure {
  padding: 0;
  margin: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  figcaption {
    @extend %fancycaption;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 0;
      margin: 0 0 rem(24px) 0;
    }
  }

}

img {
  width: 100%;
  height: auto;
}

.video-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  line-height: 0;

  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.full-video {
    height: 100%;
    max-width: 100%;
    position: absolute;

    video {
      object-fit: cover;
    }
  }
}

.videopost {
  @include ioe;
}


