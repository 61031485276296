// ======================================================================
// 06-components - Showtime
// ======================================================================

.show-time {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.showtime-item {
  position: absolute;
  width: 100%;
  height: 100%;


  &.cover img {
    object-fit: cover;

  }

  &.contain img {
    object-fit: contain;

  }

  img {
    display: block;
    width: 100%;
    height: 100%;


  }



}

.show-time-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: rem(24px);
  grid-row-gap: rem(24px);
  align-items: center;
  justify-content: center;

  .info {
    grid-column: 1 / 2;
    grid-row: 1;
  }
  @include media("screen", ">=tablet", "<=desktop", "retina2x") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
  @include media("screen", ">=tablet") {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
  @include media("screen", ">=tablet", "<=desktop", "retina2x") {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
  @include media("screen", ">=desktop") {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
}
