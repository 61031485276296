// ======================================================================
// 06-components - Transitons
// ======================================================================

.transition-fade {
  transition: 0.2s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}
