// ======================================================================
// 06-components - Split
// ======================================================================

.split {
  @include media("screen", "<=tablet") {
    grid-column-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'right' 'left';
  }
  display: grid;
  grid-column-gap: rem(72px);
  grid-row-gap: rem(24px);
  grid-template-columns: 50% auto;
  grid-template-rows: auto;
  grid-template-areas: 'right left';
}

.split-left {
  grid-area: left;
}

.split-right {
  grid-area: right;

  h1,
  p {
    @include minbreak($bp-large) {
      padding: 0;
    }
  }

  figure {
    display: block;
    line-height: 0;
    margin: rem(24px) 0 rem(24px) 0;
 
    &.videopost, &.imagepost {
      margin: rem(24px) 0 rem(24px) 0;
  }
  }
}
