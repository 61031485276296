// ======================================================================
// 06-components - Site Header
// ======================================================================

#site-header {
  @include media("screen", ">=375px", "<=667px", "retina2x") {
    padding: 0 rem(24px);
  }
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'logo menu';
  align-items: flex-start;
  padding: 0 rem($gutter);
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: $bright;
}
