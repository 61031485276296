// ======================================================================
// 06-components - Footer
// ======================================================================

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem($gutter);
  width: 100%;
  bottom: 0;
  height: rem(32px);

  a {
    color: $gray;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    @include type-free(14px, 0, 0, 24px, true);
    text-transform: uppercase;
  }
}
