// ======================================================================
// 06-components - Triple
// ======================================================================

.full,
.triple {
  display: grid;
  grid-template-columns: rem($gutter) 1fr rem($gutter);
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  position: relative;
}

.full {
  @include media("screen", ">=375px", "<=667px", "retina2x") {
    grid-template-columns: rem(24px) 1fr rem(24px);
  }
}

.triple-left,
.triple-middle,
.triple-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.full-middle,
.triple-middle {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
}

.full-middle {
  grid-column-start: 2;
  position: relative;
}
