// ======================================================================
// 06-components - Middle
// ======================================================================

#middle {
  width: 100%;
  height: 100%;



}
